import Glide from '@glidejs/glide';

let hamburgerButon = document.querySelector('.hamburger');
let dropdown = document.querySelector('.dropdown');
let header = document.querySelector('.header');
var menuIsOpen = false;
let menuOption= document.querySelectorAll('.main-nav-item');

let sendContactButton = document.querySelector('.contactForm-data');

let opcionesSlides = {
  keyboard: true,
  autoplay: 4300,
  animationDuration: 1900,
}

var analisis = new Glide('.glide', opcionesSlides)
var prediccion = new Glide('.glide2', opcionesSlides)
var ocr = new Glide('.glide3', opcionesSlides)
var lenguaje= new Glide('.glide4', opcionesSlides)
  analisis.mount();
  prediccion.mount();
  ocr.mount();
  lenguaje.mount();


hamburgerButon.addEventListener("click", ()=>{
  toggleMenu()  
});

//fix para el menu movil
menuOption.forEach(function(menuItem, i){
  menuItem.addEventListener('click', function(){
    if(menuIsOpen){
      header.classList.toggle('active');
      //console.log('menu toggled '+i);
      menuIsOpen = false;
    };
  });
});

sendContactButton.addEventListener('click', function (e){
  e.preventDefault();

  if(validateContactForm()=== true){

    let request = new XMLHttpRequest();

    request.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        alert("Agradecemos tu mensaje, en breve nos pondremos en contacto!");
      }
      else{
        alert("No fue posible enviar la información");
      }
    };

    let params = 'bconName=' + document.getElementById('con_name').value +
        '&bconEmail=' + document.getElementById('con_email').value +
        '&bconPhone=' + document.getElementById('con_phone').value +
        '&bconMessage=' + document.getElementById('con_message').value;

    request.open("POST", "http://wwww.beetmx.com/Home/ContactReceiver", true);
    request.setRequestHeader("Content-Type", "application/json");
    request.send(params);
  }
});

function validateContactForm() {
  let txtItem = document.querySelector("input[type=text]");
  if (txtItem.value === "") {
    txtItem.style.outline = "solid red 2px";
    return false;
  }

  let acceptPrivacy = document.querySelector('.contact-privacy:checked');
  if(acceptPrivacy === null){
    alert("Es necesario aceptar la política de privacidad, por favor verifica");
    return false;
  }

  return true;
}

function toggleMenu(){
  menuIsOpen = !menuIsOpen;
  header.classList.toggle('active');
  //console.log(menuIsOpen);
}

